export const siteInfo = {
  name: "ChinaShipBD",
  navColor: "#C61260",
  secondary: "#007057",
  primary_light_bg: "#E9EFF0",
  asideColor: "#fff",
  payable: 0.6,
  minimum_price: 1000,
  url: "https://chinashipbd.com",
  cdnUrl: "https://cdn.chinashipbd.com",
  phone: "01703412631",
  address:
    "35/2, Jogi Nagar Lane (2nd Floor), Wari, Dhaka. \n (জয়কালী মন্দিরেরপাশে)",
  email: "chinashipbd@gmail.com",
  minimum_quantity: 3,
  error: "#C4161C",
  tableHeader: "#E9F0EE",
  by_air: {
    a: 630,
    b: 750,
  },
};
